import React from 'react'

import { createHashRouter } from '@datadog/browser-rum-react/react-router-v6'
import { EpisodeState } from '@dialogue/services/dist/er/model'
import {
  Navigate,
  Outlet,
  type RouteObject,
  RouterProvider,
} from 'react-router-dom'

import { SuspenseWrapper } from 'app/components/suspense-wrapper'
import { WebViewerProvider } from 'app/containers/documents/document-details/viewer-context'
import { DrawerContextProvider } from 'app/containers/drawer-context'
import {
  MemberProfileDrawer,
  MemberProfileDrawerContext,
} from 'app/containers/patient-profile/drawer'
import { AuthGuard } from 'app/routing/authenticated.route'
import { EpisodeRedirect } from 'app/routing/patient-episode.resolver'
import PermissionsGuard from 'app/routing/permissions.guard'
import RbacGuard from 'app/routing/rbac.guard'
import RlacGuard from 'app/routing/rlac.guard'
import { usePageViews } from 'app/routing/utils'
import {
  DOCCENTER_MANAGE_FAXES,
  EMERALD_READ_PRACTITIONER_SCHEDULE,
  ER_LIST_EPISODES,
  ER_LIST_PROVIDER_EPISODES,
  ER_LIST_REMINDERS,
  ER_LIST_TASKS,
  NOTEPAD_AUTHOR_NOTE,
  VIEW_PROFILE_SCOPES,
} from 'app/scopes'

import { DocumentDrawer } from './containers/documents/document-details/drawer'
import { RouterErrorScreen } from './containers/router-error-screen'

const AuthenticatedViewLayout = React.lazy(
  () => import('app/containers/authenticated-view-layout'),
)
const DashboardLayout = React.lazy(
  () => import('app/containers/dashboard-layout'),
)
const EncounterPage = React.lazy(() => import('app/containers/encounter-page'))
const EpisodePage = React.lazy(() => import('app/containers/episode-page'))
const LoginPage = React.lazy(() => import('app/containers/login'))
const MemberProfilePage = React.lazy(
  () => import('app/containers/member-profile-page/page'),
)
const AssignedEpisodesPage = React.lazy(
  () => import('app/containers/assigned-to-me-episodes-page'),
)
const OwnDraftNotesPage = React.lazy(
  () => import('app/containers/structured-notes/own-draft-notes-page'),
)
const EpisodeListPage = React.lazy(() => import('app/containers/episode-list'))
const Inbox = React.lazy(() => import('app/containers/inbox'))
const MentionsInbox = React.lazy(() => import('app/containers/mentions'))
const Reminders = React.lazy(() => import('app/containers/reminders'))
const Tasks = React.lazy(() => import('app/containers/tasks'))
const FaxesInbox = React.lazy(() => import('app/containers/faxes/inbox'))
const FaxesOutbox = React.lazy(() => import('app/containers/faxes/outbox'))
const UserProfile = React.lazy(() => import('app/containers/user-profile'))
const SchedulePage = React.lazy(() => import('app/containers/schedule-page'))
const ActionRequestDownloadPage = React.lazy(
  () => import('app/containers/action-request-download'),
)
const TaskDrawer = React.lazy(() => import('app/containers/tasks/task-drawer'))

const AppRoot = () => {
  usePageViews()

  return (
    <SuspenseWrapper>
      <Outlet />
    </SuspenseWrapper>
  )
}

const routes: RouteObject[] = [
  {
    element: <AppRoot />,
    errorElement: <RouterErrorScreen />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        element: (
          <AuthGuard>
            <WebViewerProvider>
              <DrawerContextProvider>
                <TaskDrawer />
                <DocumentDrawer />
                <AuthenticatedViewLayout />
              </DrawerContextProvider>
            </WebViewerProvider>
          </AuthGuard>
        ),
        children: [
          {
            element: <DashboardLayout />,
            children: [
              {
                path: 'dashboard',
                element: <Inbox />,
              },
              {
                path: 'pending',
                element: (
                  <RbacGuard>
                    <PermissionsGuard permissions={[ER_LIST_EPISODES]}>
                      <EpisodeListPage
                        key={EpisodeState.PENDING}
                        episodeState={EpisodeState.PENDING}
                      />
                    </PermissionsGuard>
                  </RbacGuard>
                ),
              },
              {
                path: 'resolved',
                element: (
                  <RbacGuard>
                    <PermissionsGuard permissions={[ER_LIST_EPISODES]}>
                      <EpisodeListPage
                        key={EpisodeState.RESOLVED}
                        episodeState={EpisodeState.RESOLVED}
                      />
                    </PermissionsGuard>
                  </RbacGuard>
                ),
              },
              {
                path: 'reminders',
                element: (
                  <RbacGuard>
                    <PermissionsGuard permissions={[ER_LIST_REMINDERS]}>
                      <Reminders />
                    </PermissionsGuard>
                  </RbacGuard>
                ),
              },
              {
                path: 'tasks',
                element: (
                  <PermissionsGuard permissions={[ER_LIST_TASKS]}>
                    <Tasks />
                  </PermissionsGuard>
                ),
              },
              {
                path: 'faxes-inbox',
                element: (
                  <>
                    <PermissionsGuard permissions={[DOCCENTER_MANAGE_FAXES]}>
                      <FaxesInbox />
                    </PermissionsGuard>
                  </>
                ),
              },
              {
                path: 'faxes-outbox',
                element: (
                  <>
                    <PermissionsGuard permissions={[DOCCENTER_MANAGE_FAXES]}>
                      <FaxesOutbox />
                    </PermissionsGuard>
                  </>
                ),
              },
              {
                path: 'assigned-to-me',
                element: (
                  <PermissionsGuard permissions={[ER_LIST_PROVIDER_EPISODES]}>
                    <AssignedEpisodesPage />
                  </PermissionsGuard>
                ),
              },
              {
                path: 'own-draft-notes',
                element: (
                  <PermissionsGuard permissions={[NOTEPAD_AUTHOR_NOTE]}>
                    <OwnDraftNotesPage />
                  </PermissionsGuard>
                ),
              },
              {
                path: 'mentions',
                element: <MentionsInbox />,
              },
              { path: 'users/:userId', element: <UserProfile /> },
            ],
          },
          {
            element: (
              <>
                <MemberProfileDrawerContext>
                  <MemberProfileDrawer />
                  <Outlet />
                </MemberProfileDrawerContext>
              </>
            ),
            children: [
              {
                path: 'chat/:patientId',
                element: (
                  <RlacGuard>
                    <Outlet />
                  </RlacGuard>
                ),
                children: [
                  { index: true, element: <EpisodeRedirect /> },
                  {
                    path: ':episodeId',
                    element: (
                      <RbacGuard guardEpisode>
                        <EpisodePage />
                      </RbacGuard>
                    ),
                  },
                ],
              },
              {
                path: 'consult/:patientId/:episodeId',
                element: (
                  <RbacGuard guardEpisode>
                    <EncounterPage />
                  </RbacGuard>
                ),
              },
            ],
          },
          {
            path: 'member/:patientId',
            element: (
              <PermissionsGuard permissions={VIEW_PROFILE_SCOPES}>
                <MemberProfilePage />
              </PermissionsGuard>
            ),
          },
          {
            path: 'schedule',
            element: (
              <PermissionsGuard
                permissions={[EMERALD_READ_PRACTITIONER_SCHEDULE]}
              >
                <SchedulePage />
              </PermissionsGuard>
            ),
          },
          {
            path: 'action-requests/:eligibilityRecordId/:documentId',
            element: <ActionRequestDownloadPage />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/dashboard" replace />,
      },
    ],
  },
]

const router = createHashRouter(routes)

export const AppRouter = () => {
  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  )
}

export default AppRouter
