import { useCallback, useMemo, useState } from 'react'

import { TaskStatus, type Task } from '@dialogue/coredata'
import { Alert, Button, Space } from 'antd'
import { useTranslation } from 'react-i18next'

import { sortTasks } from 'app/components/tasks/utils'
import * as sC from 'app/containers/reminders-quick-view/styled-components'
import { useGetTasksQuery } from 'app/redux/api/emergency-room/tasks'

import TasksList from './tasks-list'

interface ErrorNoticeProps {
  onReload: () => void
}

const ErrorNotice = ({ onReload }: ErrorNoticeProps) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')

  return (
    <Space direction="vertical">
      <Alert
        type="error"
        data-dd-privacy="allow"
        message={<>{t('tasks.error.fetching')}</>}
      />
      <Button
        type="link"
        onClick={onReload}
        data-dd-privacy="allow"
        data-dd-action-name="tasks-view-error-notice:reload"
      >
        {tCommon('button.reload')}
      </Button>
    </Space>
  )
}

interface TasksViewProps {
  episodeId?: string
  documentId?: string
  parentId?: string
  noTasksLabel: string
  noTasksDueLabel: string
  alwaysShowAll?: boolean
}

const TasksView = ({
  episodeId,
  documentId,
  parentId,
  noTasksLabel,
  noTasksDueLabel,
  alwaysShowAll = false,
  ...rest
}: TasksViewProps) => {
  const { t } = useTranslation()
  const [showCompleted, setShowCompleted] = useState(alwaysShowAll)

  const {
    tasks = [],
    isLoading,
    isError,
    refetch,
  } = useGetTasksQuery(
    { episodeId, documentId, parentId: parentId || 'null' },
    {
      skip: !episodeId && !documentId && !parentId,
      selectFromResult: ({ currentData, ...rest }) => ({
        ...rest,
        tasks: currentData?.data,
      }),
    },
  )

  const { completedTasks, incompleteTasks } = useMemo(
    () => sortTasks(tasks),
    [tasks],
  )

  const filteredTasks = useMemo(
    () =>
      showCompleted ? [...incompleteTasks, ...completedTasks] : incompleteTasks,
    [incompleteTasks, completedTasks, showCompleted],
  )

  const hasCompletedTasks = useMemo(() => {
    if (completedTasks.length > 0) {
      return true
    }

    return incompleteTasks.some((task) => {
      return (
        task.status === TaskStatus.DONE ||
        task.subtasks?.some(
          (subtask: Task) => subtask.status === TaskStatus.DONE,
        )
      )
    })
  }, [incompleteTasks, completedTasks])

  const toggleShowCompleted = useCallback(() => {
    setShowCompleted((value) => !value)
  }, [setShowCompleted])

  const showToggleCompletedButton =
    hasCompletedTasks && !isError && !isLoading && !alwaysShowAll

  return (
    <sC.MainContainer {...rest}>
      {isError && <ErrorNotice onReload={refetch} />}
      <TasksList
        tasks={filteredTasks}
        loading={isLoading}
        emptyText={
          <span data-dd-privacy="allow">
            {tasks.length ? noTasksDueLabel : noTasksLabel}
          </span>
        }
        showCompleted={showCompleted}
      />
      {showToggleCompletedButton && (
        <sC.ToggleCompletedContainer>
          <Button
            size="small"
            type="link"
            onClick={toggleShowCompleted}
            data-cy="toggle-completed-tasks-btn"
            data-dd-privacy="allow"
            data-dd-action-name="tasks-view:toggle:view-completed"
          >
            {t(
              showCompleted
                ? 'tasks.list.hideCompleted'
                : 'tasks.list.showCompleted',
            )}
          </Button>
        </sC.ToggleCompletedContainer>
      )}
    </sC.MainContainer>
  )
}

export default TasksView
