import { type TaskPriority, TaskStatus } from '@dialogue/coredata'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { selectUserId } from 'app/redux/authentification/selectors'

import type { ReduxState } from '..'

export type SortDirection = 'oldest_to_newest' | 'newest_to_oldest'
export interface TaskFilters {
  team_id?: number[]
  assignee_id?: number[] | number
  status?: TaskStatus[]
  priority?: TaskPriority[]
  scheduled_before?: string
  scheduled_after?: string
  title?: string
}

export type FilterKeys =
  | 'language'
  | 'location'
  | 'priority'
  | 'issueType'
  | 'teamAction'

export enum FilterType {
  LANGUAGE = 'language',
  LOCATION = 'location',
  PRIORITY = 'priority',
  ISSUE_TYPE = 'issueType',
  TEAM_ACTION = 'teamAction',
  AGE = 'age',
  COMMUNICATION_METHOD = 'communicationMethod',
}

export type SelectedFilters = Record<FilterKeys | string, string[]>

export interface DashboardLayoutState {
  activeTeam: string | null
  expandedTeamGroups: Record<string, string[]>
  episodesFilters: Record<string, SelectedFilters>
  taskFilters: TaskFilters | null
}

export const getDefaultTaskFilters = (userId?: number | null): TaskFilters => ({
  assignee_id: userId ?? undefined,
  status: [TaskStatus.TO_DO],
})

export const INITIAL_STATE: DashboardLayoutState = {
  activeTeam: null,
  expandedTeamGroups: {},
  episodesFilters: {},
  taskFilters: null,
}

export const { reducer, actions: dashboardLayoutActions } = createSlice({
  name: '@@dashboardLayout',
  initialState: INITIAL_STATE,
  reducers: {
    setExpandedTeamGroups(
      state,
      action: PayloadAction<{ teamId: string; groupIds: string[] }>,
    ) {
      // for backward compatibility with persisted state.
      if (!state.expandedTeamGroups) {
        state.expandedTeamGroups = {}
      }

      state.expandedTeamGroups[action.payload.teamId] = action.payload.groupIds
    },
    setActiveTeam(state, action: PayloadAction<{ teamId: string }>) {
      state.activeTeam = action.payload.teamId
    },
    setEpisodesFilters(
      state,
      action: PayloadAction<{ filters: SelectedFilters; teamId: string }>,
    ) {
      if (!action.payload.teamId) {
        console.warn('No index key (teamId) set for persisting filters')
        return
      }

      // for backward compatibility with persisted state.
      if (!state.episodesFilters) {
        state.episodesFilters = {}
      }

      state.episodesFilters[action.payload.teamId] = action.payload.filters
    },
    clearEpisodesFilters(state, action: PayloadAction<{ teamId: string }>) {
      if (!action.payload.teamId) {
        console.warn('No index key (teamId) set for persisting filters')
        return
      }

      delete state.episodesFilters[action.payload.teamId]
    },
    setTaskFilters: (
      state,
      action: PayloadAction<{ filters: TaskFilters | null }>,
    ) => {
      state.taskFilters = action.payload.filters
    },
    clearTaskFilters: (state) => {
      state.taskFilters = null
    },
  },
})

export const selectTaskFilters = (state: ReduxState) =>
  state.dashboardLayout.taskFilters ||
  getDefaultTaskFilters(selectUserId(state))

export default reducer
