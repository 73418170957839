import { useCallback, useEffect } from 'react'

import { trackPageView } from '@snowplow/browser-tracker'
import { useLocation, useSearchParams } from 'react-router-dom'

import * as sp from 'app/services/snowplow-analytics'

export const useQuery = () => {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

export const usePostIdParams = () => {
  const query = useQuery()

  let postId: { postId: string; time?: number } | null = null

  if (query.has('postId')) {
    postId = {
      postId: query.get('postId') as string,
    }
    if (query.has('t')) {
      postId.time = Number(query.get('t'))
    }
  }

  return postId
}
/* Used to get the value of a query parameter from the URL,
 * and then remove it from the URL.
 */
export const useQueryParam = (paramName: string) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const param = searchParams.get(paramName)

  const removeParam = useCallback(() => {
    setSearchParams(
      (next) => {
        next.delete(paramName)
        return next
      },
      { replace: true },
    )
    return param
  }, [param, paramName, setSearchParams])

  return { param, removeParam }
}

export const usePageViews = () => {
  const location = useLocation()

  useEffect(() => {
    sp.additionalContext.path = location.pathname
    trackPageView()
  }, [location])
}
