import {
  useLazyGetIncomingFaxDownloadUrlQuery,
  useLazyGetOutgoingFaxDownloadUrlQuery,
} from '../api/document-center/faxes'
import { useLazyGetMemberDocumentDownloadUrlQuery } from '../api/document-center/member-documents'

import { DocumentSource } from './viewer'

/**
 * A custom hook for exposing a fetch function for downloading a document URL based on its source.
 */
export function useLazyDocumentDownloadUrl(documentSource: DocumentSource) {
  const [triggerIncomingFaxQuery, incomingFaxQuery] =
    useLazyGetIncomingFaxDownloadUrlQuery()
  const [triggerMemberDocumentQuery, memberDocumentQuery] =
    useLazyGetMemberDocumentDownloadUrlQuery()
  const [triggerOutgoingFaxQuery, outgoingFaxQuery] =
    useLazyGetOutgoingFaxDownloadUrlQuery()

  let fetchDownloadUrl, query

  type LazyDocumentDownloadUrlQuery =
    | typeof incomingFaxQuery
    | typeof memberDocumentQuery
    | typeof outgoingFaxQuery

  switch (documentSource) {
    case DocumentSource.INCOMING_FAX_DOCUMENT:
      fetchDownloadUrl = triggerIncomingFaxQuery
      query = incomingFaxQuery
      break
    case DocumentSource.DOCUMENT_CENTER:
      fetchDownloadUrl = triggerMemberDocumentQuery
      query = memberDocumentQuery
      break
    case DocumentSource.OUTGOING_FAX_DOCUMENT:
      fetchDownloadUrl = triggerOutgoingFaxQuery
      query = outgoingFaxQuery
      break

    default:
      query = {
        data: undefined,
        isError: false,
        error: undefined,
      } as LazyDocumentDownloadUrlQuery
      break
  }

  return [fetchDownloadUrl, query] as const
}
