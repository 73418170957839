import type { ReactElement, ReactNode } from 'react'

import { useAppSelector } from 'app/hooks'
import { selectHasPermissions } from 'app/redux/authentification/selectors'

interface Props {
  permissions: string[]
  children: ReactNode
  fallback?: ReactNode
}

export const PermissionsGuard = ({
  permissions,
  children,
  fallback,
}: Props): ReactElement | null => {
  const isAllowed = useAppSelector((state) =>
    selectHasPermissions(state, permissions),
  )

  if (!isAllowed) {
    return <>{fallback}</> ?? null
  }

  return <>{children}</>
}
