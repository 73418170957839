// @ts-strict-ignore
import type {
  Questionnaire,
  QuestionnaireType,
} from '@dialogue/services/dist/er/model'
import {
  type PayloadAction,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'
interface State {
  fetching: boolean
  error: Error | null
  data: Questionnaire[]
  totalItems: number
  selected?: Questionnaire['id']
  filter?: QuestionnaireType
}

export const INITIAL_STATE: State = {
  fetching: false,
  error: null,
  data: [],
  totalItems: 0,
  filter: null,
}

export const { actions: questionnairesActions, reducer } = createSlice({
  name: '@@questionnaires',
  initialState: INITIAL_STATE,
  reducers: {
    get: (
      state,
      action: PayloadAction<{
        memberId: string
        type?: QuestionnaireType
        offset?: number
        limit?: number
      }>,
    ) => {
      state.error = null
      state.fetching = true
      state.filter = action.payload.type
    },
    error: (state, { payload }: PayloadAction<Error>) => {
      state.fetching = false
      state.error = payload
    },
    receive: (
      state,
      { payload }: PayloadAction<{ data: Questionnaire[]; totalItems: number }>,
    ) => {
      state.fetching = false
      state.data = payload.data
      state.totalItems = payload.totalItems
    },
    select: (state, { payload }: PayloadAction<number>) => {
      state.selected = payload
    },
  },
})

export const selectQuestionnaires = (state: ReduxState) =>
  state.questionnaires.data

export const selectQuestionnairesTotalItems = (state: ReduxState) =>
  state.questionnaires.totalItems

export const selectQuestionnairesFetching = (state: ReduxState) =>
  state.questionnaires.fetching

export const selectQuestionnairesError = (state: ReduxState) =>
  state.questionnaires.error

export const selectSelectedQuestionnaireId = (state: ReduxState) =>
  state.questionnaires.selected

export const selectQuestionnairesFilter = (state: ReduxState) =>
  state.questionnaires.filter

export const selectSelectedQuestionnaire = createSelector(
  selectQuestionnaires,
  selectSelectedQuestionnaireId,
  (questionnaires, selectedId) =>
    questionnaires.find(({ id }) => id === selectedId),
)

export default reducer
