// @ts-strict-ignore
import { EmergencyRoomTypes } from '@dialogue/services'
import { notification } from 'antd'
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'typed-redux-saga/macro'
import type { Action, ActionType } from 'typesafe-actions'

import i18n from 'app/i18n'
import type { ReduxState } from 'app/redux'
import { remindersActions, RemindersTypes } from 'app/redux/reminders'
import { track } from 'app/services/snowplow-analytics'

import { initEmergencyRoomClient } from './utils'
export const remindersState = (state: ReduxState) => state.reminders

export function* getRemindersList({
  payload: { status, offset, limit, tzDatetime, search, createdByMe },
}: ActionType<typeof remindersActions.getRemindersListRequest>) {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    const { data, meta } = yield* call(
      emergencyRoom.getReminders,
      status,
      offset,
      limit,
      tzDatetime,
      search,
      createdByMe,
    )
    yield* put(
      remindersActions.getRemindersListSuccess(data, meta.total_items, status),
    )
  } catch (error) {
    yield* put(remindersActions.getRemindersListError(error))
  }
}

export function* requestRemindersForEpisode({
  payload: { episode_id },
}: ActionType<typeof remindersActions.remindersForEpisodeRequest>) {
  try {
    yield* put(remindersActions.remindersForEpisodeFetching(episode_id))
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    const { data } = yield* call(emergencyRoom.getEpisodeReminders, episode_id)
    const completedReminders = data.filter(
      (reminder) =>
        reminder.status === EmergencyRoomTypes.ReminderStatus.COMPLETED,
    )

    const incompleteReminders = data.filter(
      (reminder) =>
        reminder.status === EmergencyRoomTypes.ReminderStatus.INCOMPLETE,
    )
    const filteredReminderData = {
      completed: completedReminders,
      incomplete: incompleteReminders,
    }

    yield* put(
      remindersActions.remindersForEpisodeSuccess(
        episode_id,
        filteredReminderData,
      ),
    )
  } catch (error) {
    yield* put(remindersActions.remindersForEpisodeFailure(episode_id, error))
  }
}

export function* requestRemindersCreateNew({
  payload: { draftReminder },
}: ActionType<typeof remindersActions.create>) {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    const reminder = yield* call(emergencyRoom.postReminder, draftReminder)

    yield* put(remindersActions.createSuccess(reminder.data))
  } catch (error) {
    yield* put(remindersActions.createFailure(draftReminder, error))
    notification.error({
      message: i18n.t('reminders.errorCreate'),
      description: String(error),
    })
  }
}

function* doUpdateReminderStatus(
  reminder: EmergencyRoomTypes.Reminder | EmergencyRoomTypes.EnrichedReminder,
  status: EmergencyRoomTypes.ReminderStatus,
  onComplete?: Action,
) {
  const { id } = reminder
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    const updatedReminder = yield* call(
      emergencyRoom.updateReminderStatus,
      id,
      status,
    )

    yield* put(
      remindersActions.remindersStatusChangeSuccess(
        updatedReminder.data,
        status,
      ),
    )
    if (onComplete) {
      yield* put(onComplete)
    }
  } catch (error) {
    yield* put(remindersActions.remindersStatusChangeFailure(reminder, error))
    notification.error({
      message: i18n.t('reminders.errorUpdate'),
      description: String(error),
    })
  }
}

export function* requestReminderStatusChange({
  payload: { reminder, status, onComplete },
}: ActionType<typeof remindersActions.remindersStatusChangeRequest>) {
  yield* call(doUpdateReminderStatus, reminder, status, onComplete)
}

export function* requestBatchReminderStatusChange({
  payload: { reminders, status, onComplete },
}: ActionType<typeof remindersActions.batchRemindersStatusChangeRequest>) {
  yield* all(
    reminders.map((reminder) =>
      call(doUpdateReminderStatus, reminder, status, null),
    ),
  )

  if (onComplete) {
    yield* put(onComplete)
  }

  yield* call(track, 'batch_followups_completed', {
    count: reminders.length,
  })
}

export function* getReminderTags() {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    const response = yield* call(emergencyRoom.getReminderTags)

    yield* put(remindersActions.getReminderTagsSuccess(response.data))
  } catch (error) {
    yield* put(remindersActions.getReminderTagsError(error))
  }
}

export default function* remindersSagas() {
  yield* all([
    takeEvery(
      RemindersTypes.STATUS_CHANGE_REQUEST,
      requestReminderStatusChange,
    ),
    takeEvery(
      RemindersTypes.BATCH_STATUS_CHANGE_REQUEST,
      requestBatchReminderStatusChange,
    ),
    takeEvery(RemindersTypes.CREATE, requestRemindersCreateNew),
    takeEvery(
      RemindersTypes.GET_FOR_EPISODE_REQUEST,
      requestRemindersForEpisode,
    ),
    takeLeading(RemindersTypes.GET_TAGS_REQUEST, getReminderTags),
    takeLatest(RemindersTypes.GET_LIST_REQUEST, getRemindersList),
  ])
}
