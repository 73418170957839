import type { DataSourceOption } from '@dialogue/notepad-client'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

interface LocationsState {
  fetching: boolean
  error: Error | null
  data: DataSourceOption[]
}

export const LOCATIONS_MAPPER = {
  'CA-QC': 'Quebec',
  'CA-ON': 'Ontario',
  'CA-NS': 'Nova Scotia',
  'CA-NB': 'New Brunswick',
  'CA-MB': 'Manitoba',
  'CA-BC': 'British Columbia',
  'CA-PE': 'Prince Edward Island',
  'CA-SK': 'Saskatchewan',
  'CA-AB': 'Alberta',
  'CA-NL': 'Newfoundland',
  'CA-YT': 'Yukon',
  'CA-NU': 'Nunavut',
  'CA-NT': 'Northwest Territories',
  'CA-QC-U': 'Clinique Dialogue Ubisoft',
}

export const INITIAL_STATE: LocationsState = {
  fetching: false,
  error: null,
  data: [],
}

export const { actions: locationsActions, reducer } = createSlice({
  name: '@@locations',
  initialState: INITIAL_STATE,
  reducers: {
    get: (state) => {
      state.error = null
      state.fetching = true
    },
    error: (state, { payload }: { payload: Error }) => {
      state.fetching = false
      state.error = payload
    },
    receive: (state, { payload }: { payload: DataSourceOption[] }) => {
      state.fetching = false
      state.data = payload
    },
  },
})

export const selectLocations = (state: ReduxState) => state.locations.data

export const selectDialogueLocation = createSelector(
  [
    selectLocations,
    (_, provinceCode: keyof typeof LOCATIONS_MAPPER) => provinceCode,
  ],
  (locations, provinceCode) => {
    if (!provinceCode) return
    const findLocation = (code: keyof typeof LOCATIONS_MAPPER) =>
      locations.find(
        ({ value }) =>
          value?.toLowerCase() === LOCATIONS_MAPPER[code]?.toLowerCase(),
      )

    let location = findLocation(provinceCode)

    if (!location) {
      location = findLocation('CA-QC')
    }

    return location?.data
  },
)

export default reducer
