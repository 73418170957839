// @ts-strict-ignore
import {
  useCallback,
  useEffect,
  useMemo,
  type FocusEvent,
  type KeyboardEvent,
} from 'react'

import { ExportOutlined } from '@ant-design/icons'
import type { MemberDocumentType } from '@dialogue/document-center'
import { Button, Form, Input, Select, Tooltip, Typography } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DatePicker } from 'app/components/ant-design'
import { EpisodePicker } from 'app/components/episode-picker'
import { NotePicker } from 'app/components/note-picker'
import { PermissionsGuard } from 'app/components/permissions-guard'
import TasksView from 'app/components/tasks/tasks-view'
import { useCanEditDocument } from 'app/containers/documents/hooks/access-control'
import TasksCreateNew from 'app/containers/tasks/create-new'
import { useAppDispatch, useAppSelector, useVariation } from 'app/hooks'
import { filterSelectOptions } from 'app/lib/helpers'
import { memberDocumentsActions } from 'app/redux/documents/members'
import type { ViewerMemberDocument } from 'app/redux/documents/viewer'
import * as episodeMetaActions from 'app/redux/episode-meta/actions'
import {
  selectIssueTypes,
  selectPropertiesErrors,
} from 'app/redux/episode-meta/selectors'
import { selectPatientEpisodesData } from 'app/redux/patients/selectors'
import { ER_LIST_TASKS } from 'app/scopes'
import { Flags } from 'app/services/feature-flags'
import routes from 'app/services/routes'

import { useViewerInstance } from '../viewer-context'

import { ActivitySection } from './activity-section'
import { DetailLine, FormContainer, HeaderLine, PanelContent } from './common'
import {
  convertUndefinedToNull,
  dateFormat,
  disableFutureDates,
  documentTypeOptions,
  parseDocumentName,
} from './helpers'

interface Props {
  document: ViewerMemberDocument
  onClose: () => void
}

export const MemberDocumentPanel = ({ document, onClose }: Props) => {
  const {
    id: documentId,
    name,
    format,
    source,
    type,
    document_date: documentDate,
    member_id: memberId,
    episode_id: episodeId,
    created_from_note_id: createdFromNoteId,
    post_id: postId,
    write_access: writeAccess,
    created_by_provider: createdByProvider,
  } = document
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { viewerInstance } = useViewerInstance()

  const enableTaskSystem = useVariation(Flags.enableTaskSystem, true)

  const hasEpisodeLink = memberId && episodeId
  /*
    postId means the document originated from a chat message
    createdFromNoteId means the document originated from a note

    Both resources are permanently linked to an episode
    and therefore the document is as well
  */
  const hasPermanentEpisodeLink = !!postId || !!createdFromNoteId
  const hasNoteLink = memberId && createdFromNoteId
  const episodeRoute = postId
    ? routes.channelPost(memberId, episodeId, postId)
    : routes.channel(memberId, episodeId)
  const noteRoute = routes.patientNote(memberId, episodeId, createdFromNoteId)

  const episodesData = useAppSelector((state) =>
    selectPatientEpisodesData(state, memberId),
  )
  const documentOptions = useMemo(() => documentTypeOptions(t), [t])

  const issueTypes = useAppSelector(selectIssueTypes)
  const issueTypesErrors = useAppSelector(selectPropertiesErrors)
  const issueTypeName = issueTypes?.find(
    (issueType) =>
      issueType.id === episodesData?.[episodeId]?.health_issue_type_id,
  )?.name

  const { isEditable } = useCanEditDocument(writeAccess, createdByProvider)

  const [currentDocumentName, documentExtension] = parseDocumentName(name)

  const episodeTooltipText = useMemo(() => {
    if (postId) return t('documents.details.episodeDisabledByPostId')
    if (createdFromNoteId) return t('documents.details.episodeDisabledByNoteId')
    return ''
  }, [postId, t, createdFromNoteId])

  useEffect(() => {
    if (!issueTypes && !issueTypesErrors) {
      dispatch(episodeMetaActions.requestProperties())
    }
  }, [issueTypes, issueTypesErrors, dispatch])

  const navigate = useNavigate()

  const openEpisode = useCallback(() => {
    navigate(episodeRoute)
    onClose()
  }, [onClose, episodeRoute, navigate])

  const openNote = useCallback(() => {
    navigate(noteRoute)
    onClose()
  }, [navigate, noteRoute, onClose])

  const [form] = Form.useForm()

  const handleOnInfoChange = useCallback(
    (changedValues: {
      episode_id?: string
      created_from_note_id?: number
      document_name?: string
    }) => {
      if (changedValues.document_name) {
        // document name changes are handled by a dedicated handler
        return
      }

      // Converts any changed form values that are undefined (ie, cleared) to null so
      // that the API clears the field.
      const documentProperties = convertUndefinedToNull(changedValues)

      if (
        Object.keys(changedValues).includes('episode_id') &&
        changedValues.episode_id === undefined
      ) {
        // Clear created_from_note_id if episode_id is cleared
        documentProperties.created_from_note_id = null
      }

      dispatch(
        memberDocumentsActions.updateDocument({
          memberId,
          documentId,
          documentProperties,
        }),
      )
    },
    [memberId, documentId, dispatch],
  )

  // specific handler for document name as we do not want it sent
  // at each new keystroke
  const handleDocumentNameChange = useCallback(
    (
      event:
        | KeyboardEvent<HTMLTextAreaElement>
        | FocusEvent<HTMLTextAreaElement>,
    ) => {
      if ('key' in event && event.key === 'Enter') {
        // prevent 'Enter' from creating a new line
        event.preventDefault()
      }

      // trim trailing/leading white space, and remove line breaks
      let newDocumentName = form
        ?.getFieldsValue()
        .document_name?.trim()
        .replace(/(\r\n|\n|\r)/gm, '')

      // reset to original value if empty
      if (!newDocumentName) {
        form.resetFields(['document_name'])
        return
      } else {
        form.setFieldsValue({ document_name: newDocumentName })
        // Pass the name update to the viewer instance
        // https://docs.apryse.com/api/web/Core.Document.html#setFilename__anchor.
        viewerInstance?.Core.documentViewer
          .getDocument()
          .setFilename(newDocumentName)
      }

      if (newDocumentName !== currentDocumentName) {
        dispatch(
          memberDocumentsActions.updateDocument({
            memberId,
            documentId,
            documentProperties: {
              name: documentExtension
                ? `${newDocumentName}.${documentExtension}`
                : newDocumentName,
            },
          }),
        )
      }
    },
    [
      memberId,
      documentId,
      currentDocumentName,
      documentExtension,
      dispatch,
      form,
      viewerInstance,
    ],
  )

  return (
    <Form
      key={documentId}
      form={form}
      onValuesChange={handleOnInfoChange}
      initialValues={{ document_name: currentDocumentName }}
      disabled={!isEditable}
    >
      <PanelContent size={4} direction="vertical">
        <Form.Item name="document_name" noStyle>
          <Input.TextArea
            autoSize
            onBlur={handleDocumentNameChange}
            onPressEnter={handleDocumentNameChange}
            data-testid="document-name-input"
            size="large"
          />
        </Form.Item>
        <HeaderLine title={t('documents.sections.properties')} />
        {format && (
          <DetailLine
            label={t('documents.format')}
            value={
              <Typography.Text>
                {t(`documents.formatWithExtension`, {
                  format,
                  ext: documentExtension,
                })}
              </Typography.Text>
            }
          />
        )}
        <DetailLine
          label={t('documents.type')}
          value={
            <Form.Item noStyle name="type">
              <Select
                options={documentOptions}
                dropdownMatchSelectWidth={false}
                defaultValue={type as MemberDocumentType}
                placeholder={t('documents.typePlaceholder')}
                style={{ width: '100%' }}
                showSearch
                allowClear
                filterOption={filterSelectOptions}
                data-testid="document-type-select"
              />
            </Form.Item>
          }
        />
        <DetailLine
          label={t('documents.documentDate')}
          alignLabel={'middle'}
          value={
            <Form.Item noStyle name="document_date">
              <DatePicker
                format={dateFormat}
                placeholder={dateFormat}
                defaultValue={!!documentDate && moment(documentDate)}
                disabledDate={disableFutureDates}
                allowClear
                style={{ width: '100%' }}
                data-testid="document-date-picker"
              />
            </Form.Item>
          }
        />
        <DetailLine
          label={t('documents.source')}
          value={
            <Typography.Text>
              {source || t('documents.notAvailable')}
            </Typography.Text>
          }
        />
        <HeaderLine title={t('documents.sections.episode')} />
        <DetailLine
          label={t('documents.details.episode')}
          alignLabel="middle"
          value={
            <Tooltip title={episodeTooltipText}>
              <FormContainer>
                <Form.Item noStyle name="episode_id">
                  <EpisodePicker
                    memberId={memberId}
                    defaultId={episodeId}
                    fallbackTitle={t('episodes.missingTitle')}
                    placeholder={t('documents.details.episodePlaceholder')}
                    disabled={hasPermanentEpisodeLink || !isEditable}
                    style={{ width: '100%' }}
                  />
                </Form.Item>

                {hasEpisodeLink && (
                  <Button // use Button instead of Link as links don't work in popout windows
                    type="link"
                    size="small"
                    css={`
                      && {
                        padding: 0;
                        white-space: normal;
                        text-align: left;
                      }
                    `}
                    onClick={openEpisode}
                  >
                    <ExportOutlined />
                  </Button>
                )}
              </FormContainer>
            </Tooltip>
          }
        />
        {issueTypeName && (
          <DetailLine
            label={t('documents.issueType')}
            value={<Typography.Text>{issueTypeName}</Typography.Text>}
          />
        )}
        <DetailLine
          label={t('documents.details.note')}
          alignLabel="middle"
          value={
            <FormContainer>
              <Form.Item noStyle name="created_from_note_id">
                <NotePicker
                  memberId={memberId}
                  noteId={createdFromNoteId}
                  placeholder={t('documents.details.notePlaceholder')}
                  disabled
                />
              </Form.Item>

              {hasNoteLink && (
                <Button // use Button instead of Link as links don't work in popout windows
                  type="link"
                  size="small"
                  css={`
                    && {
                      padding: 0;
                      white-space: normal;
                      text-align: left;
                    }
                  `}
                  onClick={openNote}
                >
                  <ExportOutlined />
                </Button>
              )}
            </FormContainer>
          }
        />
        <PermissionsGuard permissions={[ER_LIST_TASKS]}>
          {enableTaskSystem && (
            <>
              <HeaderLine
                title={t('documents.sections.tasks')}
                extra={
                  <TasksCreateNew
                    memberId={memberId}
                    episodeId={episodeId}
                    documentIds={[documentId]}
                  />
                }
              />
              <TasksView
                documentId={documentId}
                noTasksLabel={t('tasks.document.noTasksForDocument')}
                noTasksDueLabel={t('tasks.document.noTasksDueForDocument')}
                data-cy="document-view-tasks"
              />
            </>
          )}
        </PermissionsGuard>
        <ActivitySection document={document} />
      </PanelContent>
    </Form>
  )
}
