import type { EmergencyRoomTypes } from '@dialogue/services'
import type { ReminderTag } from '@dialogue/services/dist/er/model'

export enum RemindersTypes {
  STATUS_CHANGE_REQUEST = '@@reminders/STATUS_CHANGE_REQUEST',
  STATUS_CHANGE_SUCCESS = '@@reminders/STATUS_CHANGE_SUCCESS',
  STATUS_CHANGE_FAILURE = '@@reminders/STATUS_CHANGE_FAILURE',

  BATCH_STATUS_CHANGE_REQUEST = '@@reminders/BATCH_STATUS_CHANGE_REQUEST',

  CREATE = '@@reminders/CREATE',
  CREATE_SUCCESS = '@@reminders/CREATE_SUCCESS',
  CREATE_FAILURE = '@@reminders/CREATE_FAILURE',

  GET_FOR_EPISODE_REQUEST = '@@reminders/GET_FOR_EPISODE_REQUEST',
  GET_FOR_EPISODE_FETCHING = '@@reminders/GET_FOR_EPISODE_FETCHING',
  GET_FOR_EPISODE_SUCCESS = '@@reminders/GET_FOR_EPISODE_SUCCESS',
  GET_FOR_EPISODE_FAILURE = '@@reminders/GET_FOR_EPISODE_FAILURE',

  GET_TAGS_REQUEST = '@@reminders/GET_TAGS_REQUEST',
  GET_TAGS_SUCCESS = '@@reminders/GET_TAGS_SUCCESS',
  GET_TAGS_ERROR = '@@reminders/GET_TAGS_ERROR',

  GET_LIST_REQUEST = '@@reminders/GET_LIST_REQUEST',
  GET_LIST_SUCCESS = '@@reminders/GET_LIST_SUCCESS',
  GET_LIST_ERROR = '@@reminders/GET_LIST_ERROR',
}

export type ReminderStatus =
  | EmergencyRoomTypes.ReminderStatus.COMPLETED
  | EmergencyRoomTypes.ReminderStatus.INCOMPLETE

export interface ReminderEpisode {
  incomplete: EmergencyRoomTypes.Reminder[]
  completed: EmergencyRoomTypes.Reminder[]
  error?: Error
  fetching?: boolean
}

interface ReminderEpisodes {
  [k: string]: ReminderEpisode
}

export interface RemindersState {
  episodes: ReminderEpisodes
  tags: ReminderTag[]
  fetching: boolean
  tagsFetching: boolean
  fetchingStatusChange: boolean
  error: Error
  errorEpisodes: Error
  tagsError: Error

  // ER list
  list: EmergencyRoomTypes.EnrichedReminder[]
  listCount: number
  incompleteCount: number
  listError: Error
  listFetching: boolean
}
