import { forwardRef } from 'react'

import { Drawer, Popover, Table, Tabs, type PopoverProps } from 'antd'
import generateCalendar from 'antd/es/calendar/generateCalendar'
import generatePicker, {
  type PickerDateProps,
  type PickerTimeProps,
} from 'antd/es/date-picker/generatePicker'
import type { Moment } from 'moment'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'
import styled from 'styled-components'

import { colors } from 'app/theme'

export const DefaultTable = styled(Table)`
  & .ant-table {
    filter: drop-shadow(0 0 1px rgba(118, 130, 138, 0.5))
      drop-shadow(0 0 4px rgba(0, 0, 0, 0.15));
  }

  .ant-table-column-sorters {
    justify-content: start;
    .ant-table-column-title {
      flex: none;
    }
    .ant-table-column-sorter {
      margin-left: 8px;
    }
  }
` as typeof Table

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    color: ${colors.textLight};
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 8px;
  }
  .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }
`

/**
 * Same as antd's Popover, but inner content can be styled
 * using styled-components.
 */
export const StylablePopover = ({
  className,
  ...rest
}: Omit<PopoverProps, 'overlayClassName'>) => (
  <Popover {...rest} overlayClassName={className} />
)

export const DatePicker = generatePicker<Moment>(momentGenerateConfig)

export interface DatePickerProps
  extends Omit<PickerDateProps<Moment>, 'picker'> {}

export interface TimePickerProps
  extends Omit<PickerTimeProps<Moment>, 'picker'> {}

export const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => (
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
))
TimePicker.displayName = 'TimePicker'

export const Calendar = generateCalendar<Moment>(momentGenerateConfig)

export const StyledDrawer = styled(Drawer)`
  top: 40px;

  &&& {
    .ant-drawer-body {
      padding: 0;
    }
    .ant-drawer-header {
      /* half the default antd padding */
      padding: 4px 12px;
    }
  }
`

export const SplitSpace = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`
