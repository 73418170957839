import { MemberDocumentType } from '@dialogue/document-center'
import type { TFunction } from 'i18next'
import moment from 'moment'

import type { ViewerMemberDocument } from 'app/redux/documents/viewer'

import { formatDocumentType } from '../../helpers'

import type { DocumentActivityMetadata } from './common'

export const parseDocumentName = (name: string) => {
  const lastPeriod = name.lastIndexOf('.')

  if (lastPeriod === -1) return [name]

  const fileName = name.substring(0, lastPeriod)
  const extension = name.substring(lastPeriod + 1)

  return [fileName, extension]
}

const DOCUMENT_TYPE_ORDER = [
  MemberDocumentType.laboratory_results,
  MemberDocumentType.medical_imaging_reports,
  MemberDocumentType.other,
  MemberDocumentType.consult_report,
  MemberDocumentType.medical_attestation,
  MemberDocumentType.requisition,
  MemberDocumentType.prescription,
  MemberDocumentType.pharmacy,
  MemberDocumentType.insurance,
  MemberDocumentType.other_third_party_communication,
  MemberDocumentType.external_record_received,
  MemberDocumentType.file_transfer_request,
  MemberDocumentType.member_record_export,
  MemberDocumentType.external_patient,
  MemberDocumentType.external_medical_note,
  MemberDocumentType.external_medical_file,
]

// Converts any changed form values that are undefined (ie, have been cleared) to null for the API
export const convertUndefinedToNull = <T>(obj: T): T => {
  const result: Record<string, unknown> = {}
  Object.keys(obj as Record<string, unknown>).forEach((key) => {
    result[key] = obj[key as keyof T] === undefined ? null : obj[key as keyof T]
  })
  return result as T
}

export const disableFutureDates = (current: moment.Moment) => {
  return current && current > moment().endOf('day')
}

export const dateFormat = 'YYYY-MM-DD'
export const dateAndHoursFormat = 'YYYY-MM-DD HH:mm:ss'

export const documentTypeOptions = (t: TFunction) =>
  // Custom sorting provided by OPS (not alphabetical)
  DOCUMENT_TYPE_ORDER.map((type) => ({
    label: formatDocumentType(t, type),
    value: type,
  }))

interface MergedDocumentActivity {
  id: string
  timestamp: string
  user_id: number
  activity_type: string
  userNickname: string
  metadata?: DocumentActivityMetadata
}

export const mergeDocumentActivity = (
  document: ViewerMemberDocument,
): MergedDocumentActivity[] => {
  /*
    For now, DC does not create activity records for all updates to a document.
    Until the remaining activity records are created, we need to build up the activity history
    using the available activity records + data from timestamp columns.
  */
  const {
    created_by_provider,
    content_updated_by_provider,
    content_locked_by_provider,
    ih_id_updated_by_provider,
    deleted_by_provider,
    created_at,
    content_updated_at,
    content_locked_at,
    activities,
    ih_id_updated_at,
    deleted_at,
  } = document

  const activitiesList: MergedDocumentActivity[] = [
    {
      id: 'created',
      timestamp: created_at,
      user_id: created_by_provider,
      activity_type: 'created',
      userNickname: '',
    },
  ]

  // Appending the activity timestamp to the id to ensure uniqueness
  // Note that userNickname will be populated in the component

  if (content_locked_at && content_locked_by_provider) {
    activitiesList.push({
      id: `signed-${content_locked_at}`,
      timestamp: content_locked_at,
      user_id: content_locked_by_provider,
      activity_type: 'signed',
      userNickname: '',
    })
  }

  if (ih_id_updated_at && ih_id_updated_by_provider) {
    activitiesList.push({
      id: `uploadedToIh-${ih_id_updated_at}`,
      timestamp: ih_id_updated_at,
      user_id: ih_id_updated_by_provider,
      activity_type: 'uploadedToIh',
      userNickname: '',
    })
  }

  if (deleted_at && deleted_by_provider) {
    activitiesList.push({
      id: `deleted-${deleted_at}`,
      timestamp: deleted_at,
      user_id: deleted_by_provider,
      activity_type: 'deleted',
      userNickname: '',
    })
  }

  activitiesList.push(
    ...activities.map((activity) => ({
      id: `${activity.id}`,
      timestamp: activity.timestamp,
      user_id: activity.user_id,
      activity_type: activity.activity_type,
      userNickname: '',
      metadata: activity.metadata,
    })),
  )

  const alreadyUpdated = activitiesList.some(
    (activity) => activity.activity_type === 'update',
  )

  if (content_updated_at && content_updated_by_provider && !alreadyUpdated) {
    activitiesList.push({
      id: `updated-${content_updated_at}`,
      timestamp: content_updated_at,
      user_id: content_updated_by_provider,
      activity_type: 'update',
      userNickname: '',
    })
  }

  return activitiesList.sort((a, b) => moment(b.timestamp).diff(a.timestamp))
}
