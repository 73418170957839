// A nullable config key, means that key can be set to null, and the app will
// gracefully degrade around it.

export enum Region {
  US = 'US',
  CA = 'CA',
  DE = 'DE',
}

export type Languages = 'EN' | 'FR' | 'DE'
export interface Config {
  REGIONS: Region[]
  REGION_LANGUAGES: Languages[]
  APP_SCHEME: string
  ASSISTANT_URL: string
  COREDATA_MM_UID: string
  DATADOG_RELEASE_ENV: 'prod-ca' | 'prod-us' | null
  EMERGENCY_ROOM_URL: string
  MATTERMOST_API_URL: string
  MATTERMOST_SOCKET_URL: string
  MESSAGE_RETRACTION_URL: string
  MULTIPASS_DOMAIN: string
  OPENTOK_API_KEY: string
  RASA_INTENTS_URL: string
  SCRIBE_DOMAIN: string
  TEMPLATES_URL: string
  USHER_DOMAIN: string
  WALDO_ENDPOINT: string
  TOKTOK_URL: string
  TELEPHONE_SERVICE_URL: string
  EMERALD_API_URL: string | null
  EMR_LINK_RECORD_CONFLICT_RESOLUTION_DOCUMENTATION_URL: string
  SNOWPLOW_APP_ID: string
  SNOWPLOW_APP_STAGE: string
  SNOWPLOW_COLLECTION_REL_URL: string
  REQUEST_ACCESS_CHANNEL: string | null
  REQUEST_ACCESS_CHANNEL_URL: string | null
  PROFILE_MENU_SUBMIT_RESOURCE: string | null
  PROFILE_MENU_REPORT_ISSUE: string | null
  PROFILE_MENUS_LUNCH_SCHEDULE: string | null
  PROFILE_MENU_WFM_REQUEST: string | null
  RECAPTCHA_SITE_KEY: string
  AUTH0_CLIENT_ID: string
  AUTH0_AUDIENCE: string
  AUTH0_ENDPOINT: string
  LOGOUT_CALLBACK_URL: string
  LAUNCHDARKLY_ID: string
  CMS_URL: string
  ECOUCH_URL: string
  NOTEPAD_URL: string
  STATUSPAGE_COMPONENT_GROUP_ID: string
  FULLCALENDAR_LICENSE: string
  PDF_APRYSE_LICENSE: string
  DOCUMENT_CENTER_URL: string
  TIMEKEEPER_URL: string | null
  SPRIG_ENV_ID: string
}
