import { useCallback, useState } from 'react'

import { Button, Popover } from 'antd'
import { useTranslation } from 'react-i18next'

import AutofillContent from './popover-content'

interface AutofillProps {
  memberId: number
  documentId?: string
}

const AutofillButton = ({ memberId }: AutofillProps) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <Popover
      trigger="click"
      open={isOpen}
      showArrow={false}
      content={<AutofillContent memberId={memberId} onClose={handleClose} />}
      onOpenChange={setIsOpen}
      destroyTooltipOnHide
    >
      <Button type="ghost" style={{ marginRight: '2rem' }}>
        {t('documents.actions.autofill')}
      </Button>
    </Popover>
  )
}

export default AutofillButton
