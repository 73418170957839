import { useMemo } from 'react'

import { ActivityType } from '@dialogue/document-center'
import { Timeline, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'app/hooks'
import { formatPhoneNumber } from 'app/lib/helpers'
import type { ViewerMemberDocument } from 'app/redux/documents/viewer'
import { selectUserIdToNickname } from 'app/redux/practitioners/selectors'

import {
  ActivitiesContainer,
  ActivityTimelineBlock,
  HeaderLine,
} from './common'
import { mergeDocumentActivity } from './helpers'

interface Props {
  document: ViewerMemberDocument
}

export const ActivitySection = ({ document }: Props) => {
  const { t } = useTranslation()
  const mergedDocumentActivities = useMemo(() => {
    return mergeDocumentActivity(document)
  }, [document])

  const userIds = useMemo(
    () => mergedDocumentActivities.map((a) => a.user_id),
    [mergedDocumentActivities],
  )

  const userIdToNickname = useAppSelector((state) =>
    selectUserIdToNickname(state, userIds),
  )

  const documentActivities = useMemo(() => {
    return mergedDocumentActivities.map((activity) => {
      const userNickname = userIdToNickname[activity.user_id]
      return {
        ...activity,
        userNickname,
      }
    })
  }, [mergedDocumentActivities, userIdToNickname])

  const lockedToolTipMessage = t('documents.activity.lockedToolTip')

  return (
    <>
      <HeaderLine title={t('documents.sections.activity')} />
      <ActivitiesContainer>
        <Typography.Text type="secondary">
          {t('documents.activity.sortOrderText')}
        </Typography.Text>
        <Timeline>
          {documentActivities.map((activity) => {
            return (
              <Timeline.Item key={activity.id}>
                <ActivityTimelineBlock
                  personName={activity.userNickname}
                  timestamp={activity.timestamp}
                  metadataFields={
                    activity.metadata?.receiver_number
                      ? [formatPhoneNumber(activity.metadata.receiver_number)]
                      : []
                  }
                  activityType={t(
                    `documents.activity.${activity.activity_type}`,
                    activity.activity_type,
                  )}
                  tooltipMessages={
                    activity.activity_type === ActivityType.lock
                      ? [lockedToolTipMessage]
                      : []
                  }
                />
              </Timeline.Item>
            )
          })}
        </Timeline>
      </ActivitiesContainer>
    </>
  )
}
