// @ts-strict-ignore
import { useCallback } from 'react'

export const useClipboard = (
  value: string | number | null,
  targetWin?: WindowProxy | null,
) => {
  targetWin = targetWin || window

  const strValue = (value || '').toString()
  return useCallback(
    () => strValue && targetWin.navigator.clipboard.writeText(strValue),
    [strValue, targetWin],
  )
}
