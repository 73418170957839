// @ts-strict-ignore
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { formatPhoneNumber } from 'app/lib/helpers'

export interface Address {
  city?: string | null
  country?: string | null
  fax?: string | null
  name?: string | null
  phone?: string | null
  phone_ext?: string | null
  postal_code?: string | null
  province?: string | null
  street_address1?: string | null
  street_address2?: string | null
}

export const AddressDisplay = ({
  city,
  country,
  fax,
  name,
  phone,
  phone_ext,
  postal_code,
  province,
  street_address1,
  street_address2,
}: Address) => {
  const { t } = useTranslation()

  const streetFormatted = [street_address1, street_address2]
    .filter(Boolean)
    .join(', ')
  const areaFormatted = [city, province, country, postal_code]
    .filter(Boolean)
    .join(', ')

  const streetLine = <div>{streetFormatted}</div>
  const areaLine = <div>{areaFormatted}</div>
  const phoneLine = (
    <div>
      <Typography.Text copyable={{ text: phone }}>
        {t('medicalProfile.preferredPharmacy.phone', {
          phone: formatPhoneNumber(phone, phone_ext, null),
        })}
      </Typography.Text>
    </div>
  )

  const faxLine = (
    <Typography.Text copyable={{ text: fax }}>
      {t('medicalProfile.preferredPharmacy.fax', {
        fax: formatPhoneNumber(fax, null, null),
      })}
    </Typography.Text>
  )

  return (
    <>
      <strong>{name}</strong>
      {streetFormatted && streetLine}
      {areaFormatted && areaLine}
      {phone && phoneLine}
      {fax && faxLine}
    </>
  )
}
