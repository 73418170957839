import type { EmergencyRoomTypes } from '@dialogue/services'

export enum EpisodeViewTypes {
  CLEAR_EPISODE_VIEW = '@@episodeView/CLEAR_EPISODE_VIEW',

  RESOLVE_EPISODE = '@@episodes/RESOLVE_EPISODE',
  REOPEN_EPISODE = '@@episodes/REOPEN_EPISODE',
  SET_EPISODE_TO_PENDING = '@@episodes/SET_EPISODE_TO_PENDING',

  UPDATE_EPISODE_STATE_SUCCESS = '@@episodes/UPDATE_EPISODE_STATE_SUCCESS',
  UPDATE_EPISODE_STATE_FAILURE = '@@episodes/UPDATE_EPISODE_STATE_FAILURE',
}

export interface Episode {
  id: string
  patient_id: number
  team_id: string
  created_at: string
  queue?: string
  queueUpdating?: boolean
  queueError?: Record<string, any>
  teamAction?: string
  fetchingEpisode?: boolean
  assignee_id: number
  updatingAssignee?: boolean
  updatingAssigneeError?: Error
  state: EmergencyRoomTypes.EpisodeState
  title?: string
  health_issue_type_id?: string
  health_issue_type_name?: string
  priority_id?: string
  outcome_id?: string
  subject_id?: number
  bot_status?: string
  brand_id?: string
  patient_first_name?: string | null
  patient_last_name?: string | null
  patient_preferred_name?: string | null
  communication_channel_id?: 'audio' | 'video' | 'phone_only' | null
  communication_methods: EmergencyRoomTypes.CommunicationMethods[]
}

export interface EpisodeViewState {
  episode: Partial<Episode>
  updatingState: boolean
  updatingStateError?: Error
}
