import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  type Dispatch,
  type ReactElement,
  type SetStateAction,
} from 'react'

import type { MemberDocument } from '@dialogue/document-center'
import type { WebViewerInstance } from '@pdftron/webviewer'

import { useAppSelector } from 'app/hooks'
import { selectDocumentViewerState } from 'app/redux/documents/viewer'

interface Modal {
  type: MODAL_TYPE
  onCancel?: () => void
  onConfirm?: (_?: any) => void
}

type ModalState = Modal | null

export type WebViewerContextType = {
  // viewerInstance refers to the actual Class of the viewer
  viewerInstance: WebViewerInstance | null
  setViewerInstance: (instance: WebViewerInstance) => void

  // isViewerEditable captures whether the viewer is in edit mode or not.
  isViewerEditable: boolean
  setIsViewerEditable: Dispatch<SetStateAction<boolean>>

  // loadedDocumentId captures whether there is a document currently loaded into the viewer or not.
  loadedDocumentId: string | null
  setLoadedDocumentId: Dispatch<SetStateAction<string | null>>

  // documentHasSignature captures whether there is 1+ signature annotation(s) in the current doc.
  documentHasSignature: boolean
  setDocumentHasSignature: Dispatch<SetStateAction<boolean>>

  // modal captures whether we should show a modal in the documentViewer to the user, and which one.
  modal: ModalState
  setModal: Dispatch<SetStateAction<ModalState>>

  // nextDocument captures whether there is an upcoming document to load into the viewer.
  nextDocument: MemberDocument | null
  setNextDocument: Dispatch<SetStateAction<MemberDocument | null>>
}

export const WebViewerContext = createContext<WebViewerContextType | null>(null)

export enum MODAL_TYPE {
  CONFIRM_REVIEW = 'confirm-review',
  CONFIRM_EDIT = 'confirm-edit',
  CONFIRM_SAVE = 'confirm-save',
  CONFIRM_DELETE = 'confirm-delete',
  WARN_REQUIRED_FIELDS = 'warn-required-fields',
}

export const WebViewerProvider = ({ children }: { children: ReactElement }) => {
  const [viewerInstance, setViewerInstance] =
    useState<WebViewerInstance | null>(null)
  const [isViewerEditable, setIsViewerEditable] = useState<boolean>(false)
  const [loadedDocumentId, setLoadedDocumentId] = useState<string | null>(null)
  const [documentHasSignature, setDocumentHasSignature] =
    useState<boolean>(false)
  const [modal, setModal] = useState<{
    type: MODAL_TYPE
    onCancel?: () => void
    onConfirm?: () => void
  } | null>(null)
  const [nextDocument, setNextDocument] = useState<MemberDocument | null>(null)

  useEffect(() => {
    if (!viewerInstance) {
      return
    }

    // Every time annotations are loaded into the doc, check if there's at least one signature.
    viewerInstance?.Core.documentViewer.addEventListener(
      'annotationsLoaded',
      () => {
        const annotations =
          viewerInstance?.Core.annotationManager.getAnnotationsList()
        const signatures = annotations.filter(
          (annotation) => annotation?.Subject === 'Signature',
        )
        setDocumentHasSignature(signatures.length > 0)
      },
    )
  }, [viewerInstance, setDocumentHasSignature])

  return (
    <WebViewerContext.Provider
      value={{
        viewerInstance,
        setViewerInstance,
        isViewerEditable,
        setIsViewerEditable,
        loadedDocumentId,
        setLoadedDocumentId,
        documentHasSignature,
        setDocumentHasSignature,
        modal,
        setModal,
        nextDocument,
        setNextDocument,
      }}
    >
      {children}
    </WebViewerContext.Provider>
  )
}

export const useViewerInstance = () => {
  const webViewerContext = useContext(WebViewerContext)
  const {
    viewerInstance,
    isViewerEditable,
    setIsViewerEditable,
    loadedDocumentId,
    setLoadedDocumentId,
    documentHasSignature,
    setDocumentHasSignature,
    modal,
    setModal,
    nextDocument,
    setNextDocument,
  } = webViewerContext || {}

  const reduxDoc = useAppSelector(
    (state) => selectDocumentViewerState(state).selectedDocument,
  )

  // Updates have happened, force the viewer to refresh so it reflects them.
  const refreshViewer = useCallback(() => {
    if (!viewerInstance) {
      console.warn('viewer instance does not exist, failed to refresh')
      return
    }

    const { documentViewer } = viewerInstance.Core
    documentViewer.refreshAll()
    documentViewer.updateView()
  }, [viewerInstance])

  const enableViewerEdit = useCallback(() => {
    if (!viewerInstance) {
      console.warn('viewer instance does not exist, failed to enable')
      return
    }

    const { documentViewer, annotationManager } = viewerInstance.Core
    documentViewer.disableReadOnlyMode()
    annotationManager.disableReadOnlyMode()

    // "Ribbons" refers to the toolbar groups
    // https://docs.apryse.com/documentation/web/guides/customizing-header/#customizing-ribbons
    viewerInstance?.UI.enableElements(['ribbons'])

    const annotations = annotationManager.getAnnotationsList()
    const widgets = annotations.filter(
      (annotation) => annotation?.Subject === 'Widget',
    )
    const signatures = annotations.filter(
      (annotation) => annotation?.Subject === 'Signature',
    )

    // Explicitly re-enable form fields
    widgets.forEach((annotation) => {
      // Ignoring the next code block, because we know the annotation is a Widget, and widgets have these properties. (wheras the base Annotation class does not)

      // @ts-ignore Widgets have fieldFlags property
      // https://docs.apryse.com/api/web/Core.Annotations.WidgetAnnotation.html#main
      annotation.fieldFlags.set('ReadOnly', false)

      // NICHE BUG FIX ALERT 🚨
      // For date field widgets, when ReadOnly = true, Apryse also sets disabled="disabled"
      // on the input... and updating ReadOnly = false does NOT clear this. So, we have to target
      // the field, and force it to refresh itself once we've made the annotation editable.
      // @ts-ignore Widgets have getField method
      // https://docs.apryse.com/api/web/Core.Annotations.WidgetAnnotation.html#getField__anchor
      const field = annotation.getField()
      field.refreshAppearances()
    })

    // Clear all signatures
    annotationManager.deleteAnnotation(signatures)
    setDocumentHasSignature?.(false)

    setIsViewerEditable?.(true)
    refreshViewer()
  }, [
    viewerInstance,
    refreshViewer,
    setIsViewerEditable,
    setDocumentHasSignature,
  ])

  const areRequiredFieldsFilled = useCallback(() => {
    if (!viewerInstance) {
      console.warn('viewer instance does not exist, failed to enable')
      return false
    }

    const { annotationManager } = viewerInstance.Core
    const fieldManager = annotationManager.getFieldManager()

    if (!fieldManager.areRequiredFieldsFilled()) {
      setModal?.({
        type: MODAL_TYPE.WARN_REQUIRED_FIELDS,
      })
      return false
    }

    return true
  }, [setModal, viewerInstance])

  const disableViewerEdit = useCallback(
    (keepDocumentLoaded?: boolean) => {
      if (!viewerInstance) {
        console.warn('viewer instance does not exist, failed to disable')
        return
      }

      const { documentViewer, annotationManager } = viewerInstance.Core
      documentViewer.enableReadOnlyMode()
      annotationManager.enableReadOnlyMode()
      // "Ribbons" refers to the toolbar groups
      // https://docs.apryse.com/documentation/web/guides/customizing-header/#customizing-ribbons
      viewerInstance?.UI.disableElements(['ribbons'])

      if (keepDocumentLoaded) {
        // If document is going to remain in viewer with any edits, we want to ensure
        // all editable annotations are disabled AND re-check if a signature has been added
        const annotations = annotationManager.getAnnotationsList()
        const signatures = annotations.filter(
          (annotation) => annotation?.Subject === 'Signature',
        )

        annotations.forEach((annotation) => {
          if (annotation.Subject === 'Widget') {
            // @ts-ignore We know the annotation is a Widget, and widget annotations have fieldFlags (whereas the base Annotation class does not)
            // https://docs.apryse.com/api/web/Core.Annotations.WidgetAnnotation.html#main
            annotation.fieldFlags.set('ReadOnly', true)
          }
        })
        setDocumentHasSignature?.(signatures.length > 0)
      } else {
        // By default, we clear out the loaded document; which will trigger a reload of the
        // document from document center if the viewer is left open, discarding any edits made.
        setLoadedDocumentId?.(null)
      }

      setModal?.(null)
      setIsViewerEditable?.(false)
      refreshViewer()
    },
    [
      viewerInstance,
      refreshViewer,
      setIsViewerEditable,
      setLoadedDocumentId,
      setDocumentHasSignature,
      setModal,
    ],
  )

  const getFileData = useCallback(async (): Promise<Blob | undefined> => {
    if (!viewerInstance) {
      console.warn(
        'viewer instance does not exist, failed to retrieve file data',
      )
      return
    }

    // https://docs.apryse.com/documentation/web/guides/basics/save/
    const doc = viewerInstance.Core.documentViewer.getDocument()
    const xfdfString =
      await viewerInstance.Core.annotationManager.exportAnnotations()
    const data = await doc.getFileData({ xfdfString })
    const arr = new Uint8Array(data)
    const blob = new Blob([arr], { type: 'application/pdf ' })

    return blob
  }, [viewerInstance])

  const autofillDocument = useCallback(
    (data: Record<string, any>) => {
      if (!viewerInstance) {
        console.warn('viewer instance does not exist, failed to autofill')
        return
      }
      const { annotationManager, documentViewer, Annotations } =
        viewerInstance.Core
      const Field = Annotations.Forms.Field
      const DatePickerWidgetAnnotation = Annotations.DatePickerWidgetAnnotation

      documentViewer.getAnnotationsLoadedPromise().then(() => {
        const fieldManager = annotationManager.getFieldManager()
        Object.entries(data).forEach(([key, value]) => {
          const field = fieldManager.getField(key)
          if (field instanceof Field) {
            const widget = field.widgets?.[0]

            // form fields that are date pickers will be filled through the
            // datepicker's setDate method if the value in the data is a Date
            // object. It allows to leave the formatting up to the form's
            // configuration.
            if (
              widget instanceof DatePickerWidgetAnnotation &&
              value instanceof Date
            ) {
              // There could be a chance that the datepicker is not initialized
              widget.getDatePicker()?.setDate(value)
            } else {
              field.setValue(value)
            }
          }
        })
      })
    },
    [viewerInstance],
  )

  const clearCurrentDocument = useCallback(() => {
    if (!viewerInstance) {
      console.warn('viewer instance does not exist, failed to close document')
      return
    }

    // No document is loaded, no need to action on it.
    if (!loadedDocumentId) {
      return
    }

    // If there is currently a document loaded into the viewer, clear it.
    setLoadedDocumentId?.(null)
    viewerInstance.UI.closeDocument()

    // JUST in case the document is currently being edited when this is called, reset the view.
    // @TODO(): This patch can be removed in DIA-64244
    if (isViewerEditable) {
      console.warn(
        'document was being edited, safely resetting back to read only.',
      )
      disableViewerEdit()
    }
  }, [
    viewerInstance,
    loadedDocumentId,
    isViewerEditable,
    setLoadedDocumentId,
    disableViewerEdit,
  ])

  // If there's no selectedDocument in redux, clear the current document
  // FIXME: this is a confusing way to manage the loadedDocument in 2 places
  if (!reduxDoc?.id) {
    clearCurrentDocument()
  }

  const handleViewerError = useCallback(
    (e: Error) => {
      if (!viewerInstance) {
        console.warn('viewer instance does not exist, failed to display error')
        return
      }
      viewerInstance.UI.displayErrorMessage(e.message)
    },
    [viewerInstance],
  )

  return {
    viewerInstance,
    isViewerEditable,
    enableViewerEdit,
    disableViewerEdit,
    loadedDocumentId,
    setLoadedDocumentId,
    documentHasSignature,
    setDocumentHasSignature,
    getFileData,
    modal,
    setModal,
    nextDocument,
    setNextDocument,
    autofillDocument,
    clearCurrentDocument,
    handleViewerError,
    areRequiredFieldsFilled,
  }
}

export default WebViewerContext
