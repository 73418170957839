// @ts-strict-ignore
import type { EmeraldTypes, EmergencyRoomTypes } from '@dialogue/services'
import type { Appointment as TkAppointment } from '@dialogue/timekeeper'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { Moment } from 'moment'

import type { ReduxState } from 'app/redux'
import type { Episode } from 'app/redux/episode-view/types'

interface FormData {
  episode_id: string
  provider_id: number
  appointment_type: string
  start_date: Moment
  end_date: Moment
}

export type FormSubmitData = FormData & {
  episode: EmergencyRoomTypes.EnrichedEpisode | Partial<Episode>
}

interface State {
  creatingAppointment: boolean
  createAppointmentError: Error | null
  draftId: string | null
  updatingAppointment: boolean
  updateAppointmentError: Error | null
}

export const convertAppointmentFormData = (
  formData: FormSubmitData,
): EmeraldTypes.V2.NewAppointment => {
  const source = window.location.hash ? `cp:${window.location.hash}` : 'cp'

  return {
    start_at: formData.start_date.toISOString(),
    end_at: formData.end_date.toISOString(),
    appointment_type: formData.appointment_type,
    episode_id: formData.episode_id,
    guardian_id: formData.episode?.patient_id,
    patient_id: formData.episode?.subject_id || formData.episode?.patient_id,
    provider_id: formData.provider_id,
    source,
  }
}

const INITIAL_STATE: State = {
  creatingAppointment: false,
  createAppointmentError: null,
  draftId: null,
  updatingAppointment: false,
  updateAppointmentError: null,
}

export const { actions: schedulingAppointmentActions, reducer } = createSlice({
  name: '@@scheduling/appointments',
  initialState: INITIAL_STATE,
  reducers: {
    create: {
      reducer(
        state,
        action: PayloadAction<{
          newAppointment: EmeraldTypes.V2.NewAppointment
        }>,
      ) {
        state.creatingAppointment = true
        state.createAppointmentError = null
      },
      prepare({
        appointmentFormData,
      }: {
        appointmentFormData: FormSubmitData
      }) {
        return {
          payload: {
            newAppointment: convertAppointmentFormData(appointmentFormData),
          },
        }
      },
    },
    created(
      state,
      action: PayloadAction<{
        providerId: number
        appointment: EmeraldTypes.V2.Appointment
        tkAppointment: TkAppointment | null
      }>,
    ) {
      state.creatingAppointment = false
      state.createAppointmentError = null
    },
    createFailed(state, { payload }: PayloadAction<Error>) {
      state.creatingAppointment = false
      state.createAppointmentError = payload
    },
    update: {
      reducer(
        state,
        action: PayloadAction<{
          appointmentId: string
          updatedAppointment: EmeraldTypes.V2.NewAppointment
        }>,
      ) {
        state.updatingAppointment = true
        state.updateAppointmentError = null
      },
      prepare({
        appointmentId,
        appointmentFormData,
      }: {
        appointmentId: string
        appointmentFormData: FormSubmitData
      }) {
        return {
          payload: {
            appointmentId,
            updatedAppointment: convertAppointmentFormData(appointmentFormData),
          },
        }
      },
    },
    updated(
      state,
      action: PayloadAction<{
        providerId: number
        appointment: EmeraldTypes.V2.Appointment
        tkAppointment: TkAppointment | null
      }>,
    ) {
      state.updatingAppointment = false
    },
    updateFailed(state, { payload }: PayloadAction<{ error: Error }>) {
      state.updatingAppointment = false
      state.updateAppointmentError = payload.error
    },
  },
})

export default reducer

export const selectDraftId = (state: ReduxState) =>
  state.scheduling.appointments.draftId
