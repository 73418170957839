// @ts-strict-ignore
import { StrictMode } from 'react'

import { notification } from 'antd'
import { createRoot } from 'react-dom/client'
import { registerSW } from 'virtual:pwa-register'

import './app/init-reporting'

import './antd-theme.less'

import './styles.scss'

import deviceInfo from 'app/services/device-info'

import App from './app'
import { ldclient } from './app/services/feature-flags'
import { handleNewUpdate } from './app/update-notification'

notification.config({ placement: 'bottomLeft' })

const root = createRoot(document.querySelector('cp-root'))

ldclient.waitUntilReady().then(() =>
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  ),
)

if (!deviceInfo.isElectron()) {
  const updateAndReload = registerSW({
    onNeedRefresh: () => handleNewUpdate(updateAndReload),
  })
}

// monkey patch console.error to convert errors prefixed with "Warning: [antd" to warnings
// bc ant-design seems to think warnings are errors
if (process.env.NODE_ENV === 'development') {
  const originalConsoleError = console.error
  console.error = (...args: any[]) => {
    if (typeof args[0] === 'string' && args[0].startsWith('Warning: [antd')) {
      console.warn(...args)
    } else {
      originalConsoleError(...args)
    }
  }
}
