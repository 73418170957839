import type { ReactNode } from 'react'

import { DrawerManagementProvider } from 'app/components/drawer-manager/context'
import { TaskDrawerProvider } from 'app/containers/tasks/task-drawer-context'

export const DrawerContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  return (
    <DrawerManagementProvider>
      <TaskDrawerProvider>{children}</TaskDrawerProvider>
    </DrawerManagementProvider>
  )
}
