// @ts-strict-ignore
import type { EmergencyRoomTypes } from '@dialogue/services'
import { action, type Action } from 'typesafe-actions'

import { type ReminderEpisode, RemindersTypes } from './types'

export const remindersStatusChangeRequest = (
  reminder: EmergencyRoomTypes.Reminder | EmergencyRoomTypes.EnrichedReminder,
  status: EmergencyRoomTypes.ReminderStatus,
  onComplete?: Action,
) =>
  action(RemindersTypes.STATUS_CHANGE_REQUEST, {
    reminder,
    status,
    onComplete,
  })
export const batchRemindersStatusChangeRequest = (
  reminders: EmergencyRoomTypes.EnrichedReminder[],
  status: EmergencyRoomTypes.ReminderStatus,
  onComplete?: Action,
) =>
  action(RemindersTypes.BATCH_STATUS_CHANGE_REQUEST, {
    reminders,
    status,
    onComplete,
  })
export const remindersStatusChangeSuccess = (
  reminder: EmergencyRoomTypes.Reminder | EmergencyRoomTypes.EnrichedReminder,
  status: EmergencyRoomTypes.ReminderStatus,
) => action(RemindersTypes.STATUS_CHANGE_SUCCESS, { reminder, status })
export const remindersStatusChangeFailure = (
  reminder: EmergencyRoomTypes.Reminder | EmergencyRoomTypes.EnrichedReminder,
  error: Error,
) => action(RemindersTypes.STATUS_CHANGE_FAILURE, { reminder, error })

export const create = (draftReminder: EmergencyRoomTypes.NewReminder) =>
  action(RemindersTypes.CREATE, { draftReminder })
export const createSuccess = (reminder: EmergencyRoomTypes.Reminder) =>
  action(RemindersTypes.CREATE_SUCCESS, { reminder })
export const createFailure = (
  reminder: EmergencyRoomTypes.NewReminder,
  error: Error,
) => action(RemindersTypes.CREATE_FAILURE, { reminder, error })

export const remindersForEpisodeRequest = (episode_id: string) =>
  action(RemindersTypes.GET_FOR_EPISODE_REQUEST, { episode_id })
export const remindersForEpisodeFetching = (episode_id: string) =>
  action(RemindersTypes.GET_FOR_EPISODE_FETCHING, { episode_id })
export const remindersForEpisodeSuccess = (
  episode_id: string,
  episodeReminders: ReminderEpisode,
) =>
  action(RemindersTypes.GET_FOR_EPISODE_SUCCESS, {
    episode_id,
    episodeReminders,
  })
export const remindersForEpisodeFailure = (episode_id: string, error: Error) =>
  action(RemindersTypes.GET_FOR_EPISODE_FAILURE, { episode_id, error })

export const getReminderTagsRequest = () =>
  action(RemindersTypes.GET_TAGS_REQUEST)
export const getReminderTagsSuccess = (
  tags: EmergencyRoomTypes.ReminderTag[],
) => action(RemindersTypes.GET_TAGS_SUCCESS, { tags })
export const getReminderTagsError = (error: Error) =>
  action(RemindersTypes.GET_TAGS_ERROR, { error })

export const getRemindersListRequest = (
  status: EmergencyRoomTypes.ReminderStatus,
  offset: number,
  limit: number,
  tzDatetime: string = null,
  search: string = null,
  createdByMe: boolean = false,
) =>
  action(RemindersTypes.GET_LIST_REQUEST, {
    status,
    offset,
    limit,
    tzDatetime,
    search,
    createdByMe,
  })
export const getRemindersListSuccess = (
  reminders: EmergencyRoomTypes.EnrichedReminder[],
  count: number,
  status: EmergencyRoomTypes.ReminderStatus,
) => action(RemindersTypes.GET_LIST_SUCCESS, { reminders, count, status })
export const getRemindersListError = (error: Error) =>
  action(RemindersTypes.GET_LIST_ERROR, { error })
