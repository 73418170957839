// @ts-strict-ignore
import { useEffect, useCallback } from 'react'

import {
  useSearchParams,
  useParams,
  useMatch,
  useLocation,
} from 'react-router-dom'

import { DocumentLinkParam } from 'app/containers/documents/document-actions/types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  type DocumentSource,
  documentViewerActions,
  selectDocumentViewerState,
} from 'app/redux/documents/viewer'
import { selectEpisodeViewEpisode } from 'app/redux/episode-view/selectors'

/**
 * Intercept deeplinks, launch resolution flow, and clear parameters
 */
const useDocumentLinkResolver = () => {
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  const { patientId: pathPatientId, episodeId } = useParams<
    'patientId' | 'episodeId'
  >()

  const episodePatientId = useAppSelector((state) => {
    const episode = selectEpisodeViewEpisode(state)

    // only resolve once the correct episode has been loaded upstream,
    // to handle deeplinking when on another episode
    if (!episode || episode.id !== episodeId) {
      return null
    }

    return episode.subject_id || episode.patient_id
  })

  const isMemberProfile = useMatch('/member/*')

  // member-profile: use path param; episode view: use episode subject
  const memberId = isMemberProfile ? pathPatientId : episodePatientId

  const isValidDeeplink =
    memberId &&
    searchParams.has(DocumentLinkParam.ID) &&
    searchParams.has(DocumentLinkParam.SOURCE)

  const documentId = searchParams.get(DocumentLinkParam.ID)
  const documentSource = searchParams.get(
    DocumentLinkParam.SOURCE,
  ) as DocumentSource

  useEffect(() => {
    // setSearchParams is async, so effect is needed
    if (isValidDeeplink) {
      dispatch(
        documentViewerActions.resolveAndViewMemberDocument({
          memberId: Number(memberId),
          documentId,
          documentSource,
        }),
      )

      // clear params once we try to resolve
      setSearchParams(
        (previous) => {
          previous.delete(DocumentLinkParam.ID)
          previous.delete(DocumentLinkParam.SOURCE)
          return previous
        },
        { replace: true },
      )
    }
  }, [
    dispatch,
    memberId,
    documentId,
    documentSource,
    isValidDeeplink,
    setSearchParams,
  ])
}

/**
 * Hook which exposes selected document and handler to close it.
 *
 * Use it to hook any details container to the view-document.
 * The hook also handles closing the document for you on navigation.
 *
 */
export const useDocumentDetails = () => {
  const dispatch = useAppDispatch()
  const document = useAppSelector(
    (state) => selectDocumentViewerState(state).selectedDocument,
  )

  useDocumentLinkResolver()

  const { pathname } = useLocation()

  const closeDocument = useCallback(() => {
    dispatch(documentViewerActions.close())
  }, [dispatch])

  useEffect(() => {
    return () => {
      // On navigate away from documents
      dispatch(documentViewerActions.close())
    }
  }, [pathname, dispatch])

  return { document, closeDocument }
}
