import {
  type EnrichedEpisode,
  EpisodeState,
  type GetEpisodesResponse,
} from '@dialogue/services/dist/er/model'

import { emergencyRoomApi, Tags } from './api'

export const assignedEpisodesApi = emergencyRoomApi.injectEndpoints({
  endpoints: (build) => ({
    getMyAssignedEpisodes: build.query<EnrichedEpisode[], void>({
      async queryFn(_args, _api, _extraOptions, fetchWithBaseQuery) {
        const pageSize = 100

        const fetchSinglePage = (offset: number = 0) =>
          fetchWithBaseQuery({
            url: '/v1/practitioners/me/episodes',
            params: { state: EpisodeState.ACTIVE, limit: pageSize, offset },
          })

        try {
          const initialResponse = await fetchSinglePage()

          if (initialResponse.error) {
            return { error: initialResponse.error }
          }

          let {
            data,
            meta: { total_pages },
          } = initialResponse.data as GetEpisodesResponse

          // Single page of results
          if (total_pages === 1) return { data }

          // Multiple pages
          const extraRequests = []

          for (let i = 1; i < total_pages; i++) {
            const offset = pageSize * i
            extraRequests.push(fetchSinglePage(offset))
          }

          const extraResponses = await Promise.all(extraRequests)

          // if any of the requests failed, return the first error
          const hasError = extraResponses.find((res) => res.error)
          if (hasError) return { error: hasError.error }

          // otherwise return all episodes
          const allEpisodes = extraResponses.reduce<EnrichedEpisode[]>(
            (acc, res) => {
              const { data: episodes } = res.data as GetEpisodesResponse
              return [...acc, ...episodes]
            },
            data,
          )

          return { data: allEpisodes }
        } catch (error) {
          return { error }
        }
      },
      providesTags: () => [{ type: Tags.AssignedEpisode, id: 'LIST' }],
    }),
  }),
})

export const { useGetMyAssignedEpisodesQuery } = assignedEpisodesApi
