// @ts-strict-ignore
import type { EmergencyRoomTypes } from '@dialogue/services'
import { all, call, put, select, takeEvery } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import { alphaSortCaseInsensitive } from 'app/lib/sorters'
import { assignedEpisodesApi } from 'app/redux/api/emergency-room/assigned-episodes'
import {
  selectHasPermissions,
  selectUserHasRestrictedView,
  selectUserId,
  selectUserProfile,
} from 'app/redux/authentification/selectors'
import { episodeQueuesActions } from 'app/redux/episode-queues'
import {
  BOT_EPISODE_GROUP_ID,
  EpisodeQueuesTypes,
  type EpisodeWithIcon,
  type TeamEpisodeGroup,
  UNASSIGNED_GROUP_ID,
} from 'app/redux/episode-queues/types'
import { selectMyAssignedEpisodesFromCache } from 'app/redux/episodes/selectors'
import { initEmergencyRoomClient } from 'app/sagas/utils'
import { ER_LIST_EPISODES } from 'app/scopes'

import { poll } from './utils'

export const POLLING_INTERVAL_MS = 2000

export function* enqueueEpisode({
  payload,
}: ActionType<typeof episodeQueuesActions.enqueueEpisode>) {
  const { episodeId, selectedQueue, selectedAction } = payload
  const emergencyRoom = yield* call(initEmergencyRoomClient)

  try {
    const { data } = yield* call(
      emergencyRoom.dispatchEpisode,
      episodeId,
      selectedQueue,
      selectedAction,
    )
    yield* put(
      episodeQueuesActions.enqueueEpisodeSuccess(
        episodeId,
        data.queue_id!,
        data.queued_at!,
        data.team_action_id,
      ),
    )
  } catch (e) {
    yield* put(episodeQueuesActions.enqueueEpisodeError(episodeId, e))
  }
}

export function* groupTeamEpisodes(episodes: EpisodeWithIcon[]) {
  const userId = Number(yield* select(selectUserId))
  const userProfile = yield* select(selectUserProfile)

  const canListEpisodes = yield* select(selectHasPermissions, [
    ER_LIST_EPISODES,
  ])
  const assignedToSelf: TeamEpisodeGroup = {
    episodes: [],
  }

  // FIXME: ensure self profile is loaded once that's in Sagas
  if (userProfile) {
    assignedToSelf.assignee = {
      id: userId,
      name: `${userProfile.givenName} ${userProfile.familyName}`,
      picture: userProfile.picture,
    }
  }

  const unassigned: TeamEpisodeGroup = {
    episodes: [],
    assignee: {
      id: UNASSIGNED_GROUP_ID,
    },
  }
  const botUnassigned: TeamEpisodeGroup = {
    episodes: [],
    assignee: {
      id: BOT_EPISODE_GROUP_ID,
      name: 'Bot',
    },
  }
  // assignee ID -> episode group map
  const assignedMap: Record<number, TeamEpisodeGroup> = {}
  const userHasRestrictedView = yield* select(selectUserHasRestrictedView)

  for (const episode of episodes) {
    // unassigned --> just add to list
    if (episode.assignee_id === null) {
      if (episode.bot_status === 'active') {
        botUnassigned.episodes.push(episode)
      } else {
        unassigned.episodes.push(episode)
      }
      continue
    }

    const assigneeId = episode.assignee_id
    const isSelf = assigneeId === userId

    const group = isSelf
      ? assignedToSelf
      : (assignedMap[assigneeId] = assignedMap[assigneeId] ?? {
          episodes: [],
        })

    // populate assignee info for inbox display
    if (!group.assignee) {
      group.assignee = {
        id: assigneeId,
        name: `${episode.assignee_first_name} ${episode.assignee_last_name}`,
        picture: episode.assignee_picture,
        licenses_admin_areas: episode.assignee_licenses_admin_areas,
        main_specialization: episode.assignee_main_specialization,
      }
    }

    group.episodes.push(episode)
  }

  if (userHasRestrictedView || !canListEpisodes) {
    return [assignedToSelf]
  }

  const sortedAssigned = Object.values(assignedMap).sort((groupA, groupB) =>
    alphaSortCaseInsensitive(groupA.assignee.name, groupB.assignee.name),
  )

  const groups = [botUnassigned, unassigned, ...sortedAssigned]

  if (assignedToSelf.episodes.length) {
    groups.unshift(assignedToSelf)
  }

  return groups
}

export function* getTeamEpisodes({
  payload: { teamId },
}: ActionType<typeof episodeQueuesActions.startPollingTeamEpisodes>) {
  const emergencyRoom = yield* call(initEmergencyRoomClient)
  const userHasRestrictedView = yield* select(selectUserHasRestrictedView)
  const canListEpisodes = yield* select(selectHasPermissions, [
    ER_LIST_EPISODES,
  ])

  try {
    let episodes: EmergencyRoomTypes.EnrichedEpisode[]

    if (userHasRestrictedView || !canListEpisodes) {
      // ensure assigned episodes data is available in the store
      const assignedEpisodesPromise = yield* put(
        // @ts-ignore ThunkActions are valid Actions
        yield* call(
          assignedEpisodesApi.endpoints.getMyAssignedEpisodes.initiate,
          undefined,
          { forceRefetch: false },
        ),
      )

      // wait until the data is fetched
      yield assignedEpisodesPromise

      // get the data from cache
      const { data } = yield* select(selectMyAssignedEpisodesFromCache)
      episodes = data
    } else {
      const { data } = yield* call(emergencyRoom.getTeamEpisodes, teamId)
      episodes = data
    }

    const episodeGroups = yield* call(groupTeamEpisodes, episodes)

    yield* put(episodeQueuesActions.setTeamEpisodeGroups(teamId, episodeGroups))
  } catch (e) {
    console.error('Failed to load episodes for team', teamId)
    console.error(e)
  }
}

export function* getTeams() {
  const emergencyRoom = yield* call(initEmergencyRoomClient)

  try {
    const { data: teams } = yield* call(emergencyRoom.getTeams)

    yield* put(episodeQueuesActions.setTeams(teams))
  } catch (e) {
    console.error('Failed to load teams queues')
    console.error(e)
  }
}

export default function* episodeQueuesSagas() {
  yield* all([
    // TODO: remove once we start using ER
    takeEvery(EpisodeQueuesTypes.ENQUEUE_EPISODE, enqueueEpisode),
    poll(
      EpisodeQueuesTypes.START_POLLING_TEAM_EPISODES,
      EpisodeQueuesTypes.STOP_POLLING_TEAM_EPISODES,
      POLLING_INTERVAL_MS,
      getTeamEpisodes,
    ),
    poll(
      EpisodeQueuesTypes.START_POLLING_TEAMS,
      EpisodeQueuesTypes.STOP_POLLING_TEAMS,
      POLLING_INTERVAL_MS,
      getTeams,
    ),
  ])
}
