/* For now, use this module to define scopes from auth0-infra */

// Care platform (scopes used in the FE only)
export const CP_UPLOAD_MEMBER_DOCUMENT = 'care_platform:upload:member:document'
export const CP_WRITE_MEMBER_MESSAGE = 'care_platform:write:member_message'

// Document Center
export const DOCCENTER_CREATE_MEMBER_DOC = 'doccenter:create:member_doc'
export const DOCCENTER_DELETE_MEMBER_DOC = 'doccenter:delete:member_doc'
export const DOCCENTER_MANAGE_FAXES = 'doccenter:manage:faxes'
export const DOCCENTER_READ_MEMBER_DOC = 'doccenter:read:member_doc'
export const DOCCENTER_SEND_FAX = 'doccenter:send:fax'
export const DOCCENTER_UPDATE_MEMBER_DOC = 'doccenter:update:member_doc'

// Emerald
export const EMERALD_LIST_APPOINTMENTS = 'emerald:list:appointments'
export const EMERALD_READ_MEMBER = 'emerald:read:member'
export const EMERALD_READ_PRACTITIONER_SCHEDULE =
  'emerald:read:practitioner-schedule'
export const EMERALD_READ_PRACTITIONER_SCHEDULE_ALL =
  'emerald:read:practitioner-schedule:all'

// Timekeeper
export const TIMEKEEPER_MANAGE_ADMIN_EVENT = 'timekeeper:manage:admin_event'

// ER
export const ER_DISPATCH_EPISODE = 'er:dispatch:episode'
export const ER_LIST_EPISODES = 'er:list:episodes'
export const ER_LIST_PROVIDER_EPISODES = 'er:list:provider:episodes'
export const ER_LIST_REMINDERS = 'er:list:reminders'
export const ER_LIST_TASKS = 'er:list:tasks'
export const ER_LIST_FAXES = 'er:list:faxes'
export const ER_LIST_QUESTIONNAIRES = 'er:list:questionnaires'
export const ER_LIST_MEMBER_MEDICATION = 'er:list:member:medication'
export const ER_READ_MEMBER_MEDICAL_ID = 'er:read:member:medical_id'
export const ER_MANAGE_MEMBER_NOTES = 'er:manage:member_notes'
export const ER_MANAGE_MEMBER_PROPERTIES_MEDICAL =
  'er:manage:member_properties:medical'
export const ER_UPDATE_EPISODE = 'er:update:episode'
export const ER_UPDATE_MEMBER = 'er:update:member'
export const ER_UPDATE_EPISODE_TITLE = 'er:update:episode:title'
export const ER_UPSERT_MEMBER_MEDICATION = 'er:upsert:member:medication'

// Multipass
export const MULTIPASS_CREATE_USER = 'multipass:create:user'
export const MULTIPASS_UPDATE_USER_PIN = 'multipass:update:user:pin'

// Notepad
export const NOTEPAD_AUTHOR_NOTE = 'notepad:author:note'
export const NOTEPAD_CANCEL_NOTE = 'notepad:cancel:note'

// Opentok
export const TOKTOK_ENABLE_VIDEO = 'toktok:enable:video'

// Telephone
export const TELEPHONE_ENABLE_CALL = 'telephone:enable:call'

// Scribe
export const SCRIBE_CREATE_USER_CHARGE = 'scribe:create:user:charge'
export const SCRIBE_LIST_SERVICES = 'scribe:list:services'
export const SCRIBE_LIST_USER_ELIGIBLE_INTERVAL =
  'scribe:list:user:eligible_interval'
export const SCRIBE_LIST_USER_FAMILY_MEMBERS = 'scribe:list:user:family_members'
export const SCRIBE_LIST_USERS = 'scribe:list:users'
export const SCRIBE_READ_USER = 'scribe:read:user'
export const SCRIBE_SUPERADMIN = 'scribe:superadmin'

// Ehh
export const EHH_READ_MEMBER_ALL = 'ehh:read:member:all'

// Other
export const CREATE_USERS = 'create:users'
export const DELETE_USERS = 'delete:users'
export const READ_USERS = 'read:users'

// Compounded scopes
export const VIEW_PROFILE_SCOPES = [
  EMERALD_READ_MEMBER,
  SCRIBE_LIST_SERVICES,
  SCRIBE_LIST_USER_ELIGIBLE_INTERVAL,
]

// NOTE: Compared to how we use VIEW_PROFILE_SCOPES, for this we use it to check if
// the user has access to atleast one of the scopes.
export const VIEW_MEMBER_PROFILE_TAB_SCOPES = [
  ER_MANAGE_MEMBER_NOTES,
  ER_MANAGE_MEMBER_PROPERTIES_MEDICAL,
  ER_LIST_MEMBER_MEDICATION,
  SCRIBE_LIST_USER_FAMILY_MEMBERS,
]
